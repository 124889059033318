import React from "react";
import i18next from "i18next";
import {NavLink} from "react-router-dom";
import $ from "jquery";
import {AppContext} from "../App";

class Header extends React.Component {

    constructor(props) {
        super(props);
        $("body").on('click',function(){
            $(".pa-nav-bar").removeClass("pa-open-menu");
        })
        $(".pa-menu").on('click',function(e){
            e.stopPropagation();
        })
    }

    toggleMenu = (e) => {
        e.stopPropagation();
        $(".pa-nav-bar").toggleClass("pa-open-menu");
    }

    render = () => {
        return (
            <React.Fragment>
                <div className="pa-top-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="pa-header-address">
                                    <p><span>{i18next.t("home.contact-us")}</span> <a href={"mailto:" + AppContext.parameters.practitioner.email}>{AppContext.parameters.practitioner.email}</a></p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="pa-header-call">
                                    <p>{i18next.t("home.call-us")}<span> <a href={"tel:" + AppContext.parameters.practitioner.phone}>{AppContext.parameters.practitioner.phone}</a></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pa-main-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3 col-6">
                                <div className="pa-logo">
                                    <NavLink to="/home">
                                        <img src="/assets/images/logo-name.svg" alt="logo" className="img-fluid" style={{height: 70}}/>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="col-md-9 col-6">
                                <div className="pa-nav-bar">
                                    <div className="pa-menu">
                                        <ul>
                                            <li><NavLink to="/home">{i18next.t("menu.home")}</NavLink></li>
                                            <li><NavLink to="/well-being-care">{i18next.t("menu.care")}</NavLink></li>
                                            <li><NavLink to="/internship">{i18next.t("menu.internship")}</NavLink></li>
                                            {false &&
                                                <li><NavLink to="/blog">{i18next.t("menu.blog")}</NavLink></li>
                                            }
                                            <li><NavLink to="/about-us">{i18next.t("menu.about-us")}</NavLink></li>
                                        </ul>
                                    </div>
                                    <div className="pa-toggle-nav" onClick={this.toggleMenu} style={{marginTop: 18}}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Header;