import React, {createContext} from 'react';
import {BrowserRouter, Switch} from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
import NoLayout from "./layouts/NoLayout";
import superagent from 'superagent';
import superagentCache from 'superagent-cache';

import './App.scss';
import Preloader from "./components/Preloader";
import i18next from "i18next";

export const AppContext = createContext({
    hostName: 'http://localhost:8080',
    parameters: {
        site: {
            status: 0,
            reopening_time: ""
        },
        practitioner: {
            name: "",
            email: "",
            phone: ""
        }
    }
});

class App extends React.Component {

    state = {
        parameters: null
    }

    constructor(props) {
        super(props);

        superagentCache(superagent);

        AppContext.hostName = 'http://localhost:8080';
        AppContext.parameters = {
            site: {
                status: 0,
                reopening_time: ""
            },
            practitioner: {
                name: "",
                email: "",
                phone: ""
            }
        }

        if (process.env.NODE_ENV === 'production') {
            //AppContext.hostName = 'https://astrid-bigot.osc-fr1.scalingo.io'
            AppContext.hostName = 'https://astrid.dm-labs.org';
        }
    }

    componentDidMount() {
        superagent
            .get(AppContext.hostName + '/v1/parameters')
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    AppContext.parameters = res.body;
                    this.setState({
                        parameters: res.body
                    })
                } else {
                    console.log(err);
                }
            });
    }

    render() {
        return (
            <React.Fragment>
                <Preloader />
                <BrowserRouter>
                    <Switch>

                        {authProtectedRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                                layout={NoLayout}
                                key={idx}
                                isAuthProtected={true}
                            />
                        ))}

                        {publicRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                                layout={NoLayout}
                                key={idx}
                                isAuthProtected={false}
                            />
                        ))}

                    </Switch>
                </BrowserRouter>

            </React.Fragment>
        );
    }
}

export default App;

