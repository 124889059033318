import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import i18next from "i18next";
import {NavLink} from "react-router-dom";

class AboutUsPage extends React.Component {

    render = () => {

        return (
            <div className="pa-main-wrapper">

                <Header/>

                <div className="pa-breadcrumb">
                    <div className="container-fluid">
                        <div className="pa-breadcrumb-box">
                            <h1>{i18next.t("menu.about-us")}</h1>
                            <ul>
                                <li><NavLink to="/home">{i18next.t("menu.home")}</NavLink></li>
                                <li>{i18next.t("menu.about-us")}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="pa-about spacer-top spacer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="pa-about-img">
                                    <img src="https://res.cloudinary.com/dbbtkfgnk/image/upload/v1635360632/production/general/astrid1.jpg" alt="Astrid Bigot" className="img-fluid"/>
                                </div>
                                <p>Photographie <a href="https://www.helenegadoury.com" target="_blank" rel="noreferrer nopener">Hélène GADOURY</a></p>
                            </div>
                            <div className="col-lg-6">
                                <div className="pa-about-content">
                                    <div className="pa-heading">
                                        <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                                        <h1>Astrid Bigot</h1>
                                        <h5>{i18next.t("menu.about-us")}</h5>
                                    </div>
                                    <p>
                                        J’ai passé beaucoup de temps à étudier, d’abord la philosophie puis le droit en passant par l’esthétique cosmétique.. Je sentais que je ne rentrais pas « dans les cases ». Au plus profond de moi, je savais que les études universitaires n’étaient pas ce pour quoi j’étais destinée.
                                        Je ne me trouvais nulle part à ma place, incapable de faire les choses mécaniquement, juste parce « qu’il faut » j’ai continué d’explorer.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <p>
                                    Explorer..mais depuis une perspective extérieure, je cherchais à l’extérieur de moi, incapable à ce moment là de revenir vers moi, d’écouter cette petite voix qui nous guide quand nous osons enfin nous tourner vers l’intérieur.
                                    J’étais déconnectée de mon corps, de mes sensations, de mes émotions..de la voie/voix de mon coeur.
                                    Mes expériences de vie m’ont fait côtoyer les extrêmes, jusqu’à sombrer dans une forme de nuit noire de l’âme.
                                    En parallèle, je me suis toujours intéressée aux médecines traditionnelles ancestrales tout en nourrissant un lien particulier, très fort avec le monde végétal.
                                    La naturopathie m’a ouvert le champs des possibles et m’a transmis une vision holistique de l’être  humain. Ce sera pour moi le début d’un chemin de réconciliation avec moi-même et d’apaisement profond..
                                    J’ai suivi différents accompagnements thérapeutiques qui m’ont amené à me libérer de schémas, de croyances, d’héritages transgénérationnels..ce n’est évidemment pas terminé et je poursuis ce travail.
                                    Les huiles essentielles n’ont cessées d’être présentes à mes côtés, parfois en me bousculant pour m’amener à des prises de conscience, d’autres fois en m’apportant le réconfort et la confiance en la Vie.
                                    J’ai conservé de la naturopathie cette vision globale de l’être humain, elle imprègne en profondeur les divers enseignements que j’ai reçu (aromathérapie, olfactothérapie).
                                    A travers mes soins et mes accompagnements je vous aide à reconnecter avec vous même, avec la sagesse de votre corps pour que vous puissiez rayonner votre lumière intérieure.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <p>
                                « Nous avons oublié que, sans la puissance amoureuse qui nous habite, le monde est perdu. Tout sur Terre appelle notre regard amoureux. »<br/>
                                    <i>Christiane Singer</i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pa-value spacer-top spacer-bottom">
                    <div className="container">
                        <div className="pa-heading">
                            <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                            <h1>{i18next.t("values.title")}</h1>
                            <h5>{i18next.t("values.subtitle")}</h5>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-6 pr-0">
                                <div className="pa-value-ul pa-value-left">
                                    <ul>
                                        <li>{i18next.t("values.value1")}</li>
                                        <li>{i18next.t("values.value2")}</li>
                                        <li>{i18next.t("values.value3")}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 p-0">
                                <div className="pa-value-img">
                                    <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid"/>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 pl-0">
                                <div className="pa-value-ul pa-value-right">
                                    <ul>
                                        <li>{i18next.t("values.value6")}</li>
                                        <li>{i18next.t("values.value5")}</li>
                                        <li>{i18next.t("values.value4")}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pa-team spacer-top spacer-bottom">
                    <div className="container">
                        <div className="pa-heading">
                            <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                            <h1>{i18next.t("skill.title")}</h1>
                            <h5>{i18next.t("skill.subtitle")}</h5>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-12">
                                <a href="https://www.ecole-aroma.com" target="_blank" rel="noreferrer noopener">
                                    <div className="ps-team-box">
                                        <img src="/assets/images/training-aromatherapy.jpg" alt="aromatherapy" className="img-fluid"/>
                                        <div style={{minHeight:107}}>
                                            <h2 className="text-uppercase">{i18next.t("skill.aromatherapy")}</h2>
                                            <p>{i18next.t("skill.aromatherapy-school")}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <div className="ps-team-box">
                                    <img src="/assets/images/training-olfactotherapy.jpg" alt="olfactotherapy" className="img-fluid"/>
                                    <div style={{minHeight:107}}>
                                        <h2 className="text-uppercase">{i18next.t("skill.olfactotherapy")}</h2>
                                        <p>{i18next.t("skill.olfactotherapy-school")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <div className="ps-team-box">
                                    <img src="/assets/images/training-lunar-woman.jpg" alt="lunar woman" className="img-fluid"/>
                                    <div style={{minHeight:107}}>
                                        <h2 className="text-uppercase">{i18next.t("skill.lunar-woman")}</h2>
                                        <p>{i18next.t("skill.lunar-woman-school")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer/>

            </div>
        )
    }
}

export default AboutUsPage;