import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';

class Pager extends React.Component {

    goPage = (e, page) => {
        e.preventDefault();
        const url = new URL(window.location.href);
        let currentPage = url.searchParams.get("currentPage");
        if (currentPage == null) {
            currentPage = 1
        }
        const search = url.searchParams.get("search");
        const category = url.searchParams.get("category");
        const tag = url.searchParams.get("tag");

        let pathname = url.pathname + "?currentPage=" + page;
        if (search) {
            pathname = pathname + "&search=" + search
        }
        if (category) {
            pathname = pathname + "&category=" + category
        }
        if (tag) {
            pathname = pathname + "&tag=" + tag
        }
        this.props.parent.props.history.push(pathname)
        return true
    }

    goPreviousPage = (e) => {
        e.preventDefault();
        const url = new URL(window.location.href);
        let currentPage = parseInt(url.searchParams.get("currentPage"), 10);
        if (currentPage == null) {
            currentPage = 1
        }
        if (currentPage > 1) {
            this.goPage(e, currentPage - 1)
        }
    }

    goNextPage = (e) => {
        e.preventDefault();
        const url = new URL(window.location.href);
        let currentPage = parseInt(url.searchParams.get("currentPage"), 10);
        if (currentPage == null) {
            currentPage = 1
        }
        if (currentPage < this.props.pageCount) {
            this.goPage(e, currentPage + 1)
        }
    }

    render() {
        const numbers = Array.from({length: this.props.pageCount}, (_, i) => i + 1);

        return (
            <div className="pagination">
                <ul className="page-numbers">
                    <li>
                        <button className={classNames("page-numbers page-prev", {"disabled": this.props.currentPage === 1})}
                                onClick={(e) => {this.goPreviousPage(e)}}
                        >
                            <i className="fa fa-angle-left"/>
                        </button>
                    </li>

                    {numbers.map((number, idx) => {
                        return (
                            <li key={idx}>
                                <button  className={classNames("page-numbers", { "page-current": number === this.props.currentPage })}
                                         onClick={(e) => {this.goPage(e, number)}}
                                >{number}</button>
                            </li>
                        )
                    })}

                    <li>
                        <button className={classNames("page-numbers page-next", {"disabled": this.props.currentPage === this.props.pageCount})}
                                onClick={(e) => {this.goNextPage(e)}}
                        >
                            <i className="fa fa-angle-right"/>
                        </button>
                    </li>
                </ul>
            </div>
        )
    }
}

Pager.propTypes = {
    parent: PropTypes.object.isRequired,
    href: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired
};

export default Pager;