import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LatestNews from "../components/LatestNews";
import Testimonial from "../components/Testimonial";
import {NavLink} from "react-router-dom";
import i18next from "i18next";

class HomePage extends React.Component {

    render = () => {

        return (
            <div className="pa-main-wrapper">

                <Header />

                <div className="pa-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-6">
                                <div className="pa-banner-text">
                                    <h4 className="pa-banner-category">Bienvenue à vous,</h4>
                                    <p style={{fontSize: '20px'}}>
                                        Je vous partage ici mon univers très en lien avec le monde végétal, j’ai une connexion très forte avec la nature, les plantes me passionnent et me fascinent depuis toujours d’où mon choix de travailler avec les huiles essentielles.
                                    </p>
                                    <p style={{fontSize: '20px'}}>
                                        J’aime entrer en contact avec le végétal, me laisser enseigner par les plantes en me reliant à elles lors de méditations ou lorsque je les utilise dans mon quotidien. Elles sont de véritables alliées sur tous les plans de notre être.
                                    </p>
                                    <p style={{fontSize: '20px'}}>
                                        Je me réjouis beaucoup de vous emmener avec moi sur ce chemin, en voyage vers vous même accompagné par la sagesse des plantes.
                                    </p>
                                    <NavLink to="/appointment" className="pa-view-all">prendre rendez-vous</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pa-medicine spacer-top spacer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="pa-medicine-box">
                                    <img src="/assets/images/home-aromatherapy.jpg" alt="aromatherapy" className="img-fluid"/>
                                    <h2>{i18next.t("skill.aromatherapy")}</h2>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="pa-medicine-box">
                                    <img src="/assets/images/home-olfactotherapy.jpg" alt="olfactotherapy" className="img-fluid"/>
                                    <h2>{i18next.t("skill.olfactotherapy")}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Testimonial />

                <LatestNews />

                <Footer />

            </div>
        )
    }
}

export default HomePage;