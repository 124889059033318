import React from "react";
import Header from "../components/Header";
import i18next from "i18next";
import {NavLink} from "react-router-dom";
import Footer from "../components/Footer";

class TermsConditionsPage extends React.Component {

    renderEnglish = () => {
        return (
            <div className="pa-about spacer-top spacer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="pa-about-content">
                                <div className="pa-heading">
                                    <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid"/>
                                    <h1>{i18next.t("menu.terms-conditions")}</h1>
                                    <h5>5 September 2021</h5>
                                </div>

                                <p>This web page represents our Terms of Use and Sale ("Agreement") regarding our website, located at TermsFeed.com, and the tools we provide you (the "Website" or the "Service"). It was last posted on 13 September 2012. The terms, "we" and "our" as used in this Agreement refer to TermsFeed.</p>
                                <p>We may amend this Agreement at any time by posting the amended terms on our Website. We may or may not post notices on the homepage of our Website when such changes occur.</p>
                                <p>We refer to this Agreement, our Privacy Policy accessible at https://termsfeed.com/legal/privacy-policy, and any other terms, rules, or guidelines on our Website collectively as our "Legal Terms." You explicitly and implicitly agree to be bound by our Legal Terms each time you access our Website. If you do not wish to be so bound, please do not use or access our Website.</p>

                                <h4>Limited License</h4>
                                <p>TermsFeed grants you a non-exclusive, non-transferable, revocable license to access and use our Website in order for you to make purchases of electronic documents and related services through our Website, strictly in accordance with our Legal Terms.</p>

                                <h4>Copyrights and Trademarks</h4>
                                <p>Unless otherwise noted, all materials including without limitation, logos, brand names, images, designs, photographs, video clips and written and other materials that appear as part of our Website are copyrights, trademarks, service marks, trade dress and/or other intellectual property whether registered or unregistered ("Intellectual Property") owned, controlled or licensed by TermsFeed. Our Website as a whole is protected by copyright and trade dress. Nothing on our Website should be construed as granting, by implication, estoppel or otherwise, any license or right to use any Intellectual Property displayed or used on our Website, without the prior written permission of the Intellectual Property owner. TermsFeed aggressively enforces its intellectual property rights to the fullest extent of the law. The names and logos of TermsFeed, may not be used in any way, including in advertising or publicity pertaining to distribution of materials on our Website, without prior, written permission from TermsFeed. TermsFeed prohibits use of any logo of TermsFeed or any of its affiliates as part of a link to or from any Website unless TermsFeed approves such link in advance and in writing. Fair use of TermsFeed’s Intellectual Property requires proper acknowledgment. Other product and company names mentioned in our Website may be the Intellectual Property of their respective owners.</p>

                                <h4>Links to Third-Party Websites</h4>
                                <p>Our Website may contain links to Websites owned or operated by parties other than TermsFeed. Such links are provided for your reference only. TermsFeed does not monitor or control outside Websites and is not responsible for their content. TermsFeed’s inclusion of links to an outside Website does not imply any endorsement of the material on our Website or, unless expressly disclosed otherwise, any sponsorship, affiliation or association with its owner, operator or sponsor, nor does TermsFeed’ inclusion of the links imply that TermsFeed is authorized to use any trade name, trademark, logo, legal or official seal, or copyrighted symbol that may be reflected in the linked Website.</p>

                                <h4>Content Disclaimer</h4>
                                <p>Postings on our Website are made at such times as TermsFeed determines in its discretion. You should not assume that the information contained on our Website has been updated or otherwise contains current information. TermsFeed does not review past postings to determine whether they remain accurate and information contained in such postings may have been superseded. THE INFORMATION AND MATERIALS IN OUR WEBSITE ARE PROVIDED FOR YOUR REVIEW IN ACCORDANCE WITH THE NOTICES, TERMS AND CONDITIONS SET FORTH HEREIN. THESE MATERIALS ARE NOT GUARANTEED OR REPRESENTED TO BE COMPLETE, CORRECT OR UP TO DATE. THESE MATERIALS MAY BE CHANGED FROM TIME TO TIME WITHOUT NOTICE.</p>

                                <h4>Contracts and Legal Advice Disclaimer</h4>
                                <p>You understand that we offer legal contracts ("Contracts"), as created and/or verified by attorneys or paralegals for sale through our Website. You acknowledge that we are not attorneys or paralegals nor do we offer legal advice. You acknowledge that we do not endorse any specific attorney or paralegal or any Contracts as being better than another. We do not otherwise guarantee the legal accuracy or applicability of any Contracts for your legal needs. You will at all times look to any attorney or paralegal that you select for services as to any legal claims related to such services.</p>

                                <h4>Refunds</h4>
                                <p>We issue refunds for Contracts within 7 days of the original purchase of the Contract.</p>

                                <h4>No Warranties; Exclusion of Liability; Indemnification</h4>
                                <p>OUR WEBSITE IS OPERATED BY TermsFeed ON AN "AS IS," "AS AVAILABLE" BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY LAW, TermsFeed SPECIFICALLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT FOR OUR WEBSITE AND ANY CONTRACTS AND SERVICES YOU PURCHASE THROUGH IT. TermsFeed SHALL NOT HAVE ANY LIABILITY OR RESPONSIBILITY FOR ANY ERRORS OR OMISSIONS IN THE CONTENT OF OUR WEBSITE, FOR CONTRACTS OR SERVICES SOLD THROUGH OUR WEBSITE, FOR YOUR ACTION OR INACTION IN CONNECTION WITH OUR WEBSITE OR FOR ANY DAMAGE TO YOUR COMPUTER OR DATA OR ANY OTHER DAMAGE YOU MAY INCUR IN CONNECTION WITH OUR WEBSITE. YOUR USE OF OUR WEBSITE AND ANY CONTRACTS OR SERVICES ARE AT YOUR OWN RISK. IN NO EVENT SHALL EITHER TermsFeed OR THEIR AGENTS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF OUR WEBSITE, CONTRACTS AND SERVICES PURCHASED THROUGH OUR WEBSITE, THE DELAY OR INABILITY TO USE OUR WEBSITE OR OTHERWISE ARISING IN CONNECTION WITH OUR WEBSITE, CONTRACTS OR RELATED SERVICES, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGES. IN NO EVENT SHALL TermsFeed’ LIABILITY FOR ANY DAMAGE CLAIM EXCEED THE AMOUNT PAID BY YOU TO TermsFeed FOR THE TRANSACTION GIVING RISE TO SUCH DAMAGE CLAIM.</p>
                                <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.</p>
                                <p>WITHOUT LIMITING THE FOREGOING, TermsFeed DO NOT REPRESENT OR WARRANT THAT THE INFORMATION ON THE WEBITE IS ACCURATE, COMPLETE, RELIABLE, USEFUL, TIMELY OR CURRENT OR THAT OUR WEBSITE WILL OPERATE WITHOUT INTERRUPTION OR ERROR.</p>
                                <p>YOU AGREE THAT ALL TIMES, YOU WILL LOOK TO ATTORNEYS FROM WHOM YOU PURCHASE SERVICES FOR ANY CLAIMS OF ANY NATURE, INCLUDING LOSS, DAMAGE, OR WARRANTY. TermsFeed AND THEIR RESPECTIVE AFFILIATES MAKE NO REPRESENTATION OR GUARANTEES ABOUT ANY CONTRACTS AND SERVICES OFFERED THROUGH OUR WEBSITE.</p>
                                <p>TermsFeed MAKES NO REPRESENTATION THAT CONTENT PROVIDED ON OUR WEBSITE, CONTRACTS, OR RELATED SERVICES ARE APPLICABLE OR APPROPRIATE FOR USE IN ALL JURISDICTIONS.</p>

                                <h4>Indemnification</h4>
                                <p>You agree to defend, indemnify and hold TermsFeed harmless from and against any and all claims, damages, costs and expenses, including attorneys' fees, arising from or related to your use of our Website or any Contracts or Services you purchase through it.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderFrench = () => {
        // https://www.termsfeed.com/legal/privacy-policy/
        return (
            <div className="pa-about spacer-top spacer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="pa-about-content">
                                <div className="pa-heading">
                                    <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid"/>
                                    <h1>{i18next.t("menu.terms-conditions")}</h1>
                                    <h5>5 septembre 2021</h5>
                                </div>

                                <p>Cette page Web représente nos conditions d'utilisation et de vente (« Accord ») concernant notre site Web, situé à astrid-bigot.fr.com, et les outils que nous vous fournissons (le « Site Web » ou le « Service »). Les termes « nous » et « notre » tels qu'utilisés dans le présent Contrat font référence à astrid-bigot.fr.</p>
                                <p>Nous pouvons modifier le présent Accord à tout moment en publiant les conditions modifiées sur notre site Web. Nous pouvons ou non publier des avis sur la page d'accueil de notre site Web lorsque de tels changements surviennent.</p>
                                <p>Nous nous référons au présent Accord, à notre Politique de confidentialité accessible à l'adresse https://astrid-bigot.fr/privacy-policy, et à tous autres termes, règles ou directives sur notre site Web collectivement comme nos « Conditions légales ». Vous acceptez explicitement et implicitement d'être lié par nos conditions légales chaque fois que vous accédez à notre site Web. Si vous ne souhaitez pas être ainsi lié, veuillez ne pas utiliser ou accéder à notre site Web.</p>

                                <h4>Licence limitée</h4>
                                <p>astrid-bigot.fr vous accorde une licence non exclusive, non transférable et révocable pour accéder et utiliser notre site Web afin que vous puissiez consulter les informations publiées sur notre site Web, en stricte conformité avec nos conditions légales.</ p>

                                <h4>Droits d'auteur et marques de commerce</h4>
                                <p>Sauf indication contraire, tous les documents, y compris, sans s'y limiter, les logos, les noms de marque, les images, les dessins, les photographies, les clips vidéo et les documents écrits et autres qui apparaissent dans le cadre de notre site Web sont des droits d'auteur, des marques de commerce, des marques de service, des habillages commerciaux et / ou toute autre propriété intellectuelle, qu'elle soit enregistrée ou non (« Propriété intellectuelle »), détenue, contrôlée ou sous licence par astrid-bigot.fr. Notre site Web dans son ensemble est protégé par le droit d'auteur et l'habillage commercial. Rien sur notre site Web ne doit être interprété comme accordant, implicitement, par préclusion ou autrement, une licence ou un droit d'utilisation de toute propriété intellectuelle affichée ou utilisée sur notre site Web, sans l'autorisation écrite préalable du propriétaire de la propriété intellectuelle. astrid-bigot.fr applique agressivement ses droits de propriété intellectuelle dans toute la mesure de la loi. Les noms et logos de astrid-bigot.fr ne peuvent être utilisés de quelque manière que ce soit, y compris dans la publicité, sans l'autorisation écrite préalable de astrid-bigot.fr. astrid-bigot.fr interdit l'utilisation de tout logo de astrid-bigot.fr dans le cadre d'un lien vers ou depuis un site Web, à moins que astrid-bigot.fr n'approuve un tel lien à l'avance et par écrit. Les autres noms de produits et de sociétés mentionnés sur notre site Web peuvent être la propriété intellectuelle de leurs propriétaires respectifs.</p>

                                <h4>Liens vers des sites Web tiers</h4>
                                <p>Notre site Web peut contenir des liens vers des sites Web détenus ou exploités par des parties autres que astrid-bigot.fr. Ces liens sont fournis uniquement pour votre référence. astrid-bigot.fr ne surveille ni ne contrôle les sites Web extérieurs et n'est pas responsable de leur contenu. L'inclusion par astrid-bigot.fr de liens vers un site Web externe n'implique aucune approbation du contenu de ce site Web ou, sauf indication contraire expresse, tout parrainage, affiliation ou association avec son propriétaire, opérateur ou sponsor, et l'inclusion des liens par astrid-bigot.fr n'implique pas que astrid-bigot.fr est autorisé à utiliser tout nom commercial, marque de commerce, logo, sceau légal ou officiel ou symbole protégé par le droit d'auteur pouvant être reflété sur le site Web lié.</p>

                                <h4>Clause de non-responsabilité relative au contenu</h4>
                                <p>Les publications sur notre site Web sont effectuées à des moments déterminés par astrid-bigot.fr à sa discrétion. Vous ne devez pas supposer que les informations contenues sur notre site Web ont été mises à jour ou contiennent des informations à jour. astrid-bigot.fr n'examine pas les publications passées pour déterminer si elles restent exactes et les informations contenues dans ces publications peuvent avoir été remplacées. LES INFORMATIONS ET DOCUMENTS DE NOTRE SITE WEB SONT FOURNIS POUR VOTRE EXAMEN CONFORMÉMENT AUX AVIS, TERMES ET CONDITIONS ÉNONCÉS AUX PRÉSENTES. CES DOCUMENTS NE SONT PAS GARANTIS OU REPRÉSENTÉS COMME COMPLET, CORRECT OU À JOUR. CES MATÉRIAUX PEUVENT ÊTRE MODIFIÉS DE TEMPS EN TEMPS SANS PRÉAVIS.</p>


                                <h4>Aucune garantie ; Exclusion de responsabilité ; Indemnité</h4>
                                <p>NOTRE SITE WEB EST OPÉRÉ PAR astrid-bigot.fr « EN L'ÉTAT », « TEL QUE DISPONIBLE », SANS DÉCLARATION OU GARANTIE D'AUCUNE SORTE. DANS TOUTE LA MESURE PERMISE PAR LA LOI, astrid-bigot.fr DÉCLINE SPÉCIFIQUEMENT TOUTES GARANTIES ET CONDITIONS DE QUELQUE NATURE QUE CE SOIT, Y COMPRIS TOUTES LES GARANTIES ET CONDITIONS IMPLICITES DE QUALITÉ MARCHANDE, D'ADAPTATION À UN USAGE PARTICULIER, DE TITRE ET D'ABSENCE DE CONTREFAÇON DE NOTRE SITE WEB ET DE NOS SERVICES INFORMATIQUES. astrid-bigot.fr N'AURA AUCUNE RESPONSABILITÉ POUR TOUTE ERREUR OU OMISSION DANS LE CONTENU DE NOTRE SITE WEB, POUR VOTRE ACTION OU INACTION EN RAPPORT AVEC NOTRE SITE WEB OU POUR TOUT DOMMAGE À VOTRE ORDINATEUR OU DONNÉES OU TOUTE AUTRES DOMMAGES QUE VOUS POURRIEZ SUBIR EN LIEN AVEC NOTRE SITE WEB. VOTRE UTILISATION DE NOTRE SITE WEB ET DE TOUS CONTRATS OU SERVICES SONT À VOS PROPRES RISQUES. EN AUCUN CAS, astrid-bigot.fr N'EST TENU POUR RESPONSABLE DE TOUT DOMMAGE DIRECT, INDIRECT, PUNITIF, ACCESSOIRE, SPÉCIAL OU CONSÉCUTIF DÉCOULANT DE OU DE QUELQUE MANIÈRE QUE CE SOIT LIÉ À L'UTILISATION DE NOTRE SITE WEB, LE RETARD OU INCAPACITÉ À UTILISER NOTRE SITE WEB OU AUTREMENT DÉCOULANT EN LIEN AVEC NOTRE SITE WEB, QU'ILS SONT BASÉS SUR UN CONTRAT, UN DÉLIT, UNE RESPONSABILITÉ STRICTE OU AUTRE, MÊME SI AVISÉ DE LA POSSIBILITÉ DE TELS DOMMAGES.</p>
                                <p>CERTAINES JURIDICTIONS N'AUTORISENT PAS L'EXCLUSION OU LA LIMITATION DES DOMMAGES ACCESSOIRES OU CONSÉCUTIFS, L'EXCLUSION CI-DESSUS PEUT NE PAS S'APPLIQUER À VOUS.</p>
                                <p>SANS LIMITER CE QUI PRÉCÈDE, astrid-bigot.fr NE REPRÉSENTE NI NE GARANTIT QUE LES INFORMATIONS SUR LE SITE WEB SONT EXACTES, COMPLÈTES, FIABLES, UTILES, OPPORTUNES OU ACTUELLES OU QUE NOTRE SITE WEB FONCTIONNERA SANS INTERRUPTION OU ERREUR.</p>

                                <h4>Indemnité</h4>
                                <p>Vous acceptez de défendre, d'indemniser et de dégager astrid-bigot.fr de toute responsabilité contre toutes réclamations, dommages, coûts et dépenses, y compris les honoraires d'avocat, découlant de ou liés à votre utilisation de notre site Web.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    render = () => {
        return (
            <div className="pa-main-wrapper">

                <Header/>

                <div className="pa-breadcrumb">
                    <div className="container-fluid">
                        <div className="pa-breadcrumb-box">
                            <h1>{i18next.t("menu.terms-conditions")}</h1>
                            <ul>
                                <li><NavLink to="/home">{i18next.t("menu.home")}</NavLink></li>
                                <li>{i18next.t("menu.terms-conditions")}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {this.renderFrench()}

                <Footer/>

            </div>
        )
    }
}

export default TermsConditionsPage;