import React from "react";

class Contacts extends React.Component {

    render = () => {

        return (
            <div className="pa-price-plan spacer-top" style={{backgroundColor: "#D8BE9F"}}>
                <div className="container">
                    <div className="pa-heading">
                        <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                        <h1>Contacts</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="pa-plan-box" style={{backgroundColor: "white"}}>
                                <h2 className="pa-plan-title">Astrid</h2>
                                <ul>
                                    <li>06 33 05 11 55</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="pa-plan-box" style={{backgroundColor: "white"}}>
                                <h2 className="pa-plan-title">Clara</h2>
                                <ul>
                                    <li>06 77 96 92 54</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contacts;