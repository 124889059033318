import React from "react";
import i18next from "i18next";
import superagent from "superagent";
import {AppContext} from "../App";
import {NavLink} from "react-router-dom";

class BlogRecentPosts extends React.Component {

    state = {
        posts: []
    }

    componentDidMount() {
        superagent
            .get(AppContext.hostName + '/v1/posts')
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .query({ pageSize: 3 })
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({posts: res.body})
                } else {
                    console.log(err);
                }
            });
    }

    render = () => {
        if (this.state.posts.length < 1) {
            return null;
        }

        return (
            <div className="pa-widget pa-product-widget">
                <h2 className="pa-sidebar-title">{i18next.t("blog.recent-posts")}</h2>
                <ul>
                    {this.state.posts.map(function(post, index){
                        return (
                            <li key={index}>
                                <div className="pa-pro-wid-img">
                                    <img src={post.image} alt={post.title} className="img-fluid" style={{height: '70px'}}/>
                                </div>
                                <div className="pa-pro-wid-content">
                                    <h4><NavLink to={"/post/" + post.id}>{post.title}</NavLink></h4>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

export default BlogRecentPosts;