import React from "react";
import Header from "../components/Header";
import i18next from "i18next";
import {NavLink} from "react-router-dom";
import Footer from "../components/Footer";
import Pricing from "../components/Pricing";

class WellBeingCarePage extends React.Component {

    render = () => {

        return (
            <div className="pa-main-wrapper">
                <Header />

                <div className="pa-breadcrumb">
                    <div className="container-fluid">
                        <div className="pa-breadcrumb-box">
                            <h1>{i18next.t("menu.well-being-care")}</h1>
                            <ul>
                                <li><NavLink to="/home">{i18next.t("menu.home")}</NavLink></li>
                                <li>{i18next.t("menu.well-being-care")}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="pa-services spacer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="pa-services-img">
                                    <img src="https://res.cloudinary.com/dbbtkfgnk/image/upload/v1630848723/production/general/bottle-of-essential-oil-of-mint-and-green-leaves.jpg" alt="bottle of essential oil" className="img-fluid"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="pa-services-content">
                                    <div className="pa-heading">
                                        <img src="/assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                                        <h1>{i18next.t("well-being.title")}</h1>
                                        <h5 className={"text-uppercase"}>{i18next.t("well-being.subtitle")}</h5>
                                    </div>
                                    <p>
                                        Tous mes soins sont précédés d’un temps d’échange avec vous pour me permettre de mieux vous connaître et d’appréhender votre être dans sa globalité.
                                        Je mêle dans mes soins différentes approches (utilisations des huiles essentielles, énergétique, olfactothérapie) qui m’ont été transmises lors de mes formations, stages et tout au long de mon cheminement personnel.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row spacer-top">
                            <div className="col-lg-12">
                                <p><strong>Ode à la pureté.</strong></p>
                                <p>
                                    Un soin complet pour « faire peau neuve » et se débarrasser de tout ce qui empêche l’énergie de circuler librement (pollutions diverses, tensions, stagnations).
                                </p>
                                <p><strong>Corps à coeur</strong></p>
                                <p>
                                    Des huiles précieuses, des baumes et des poudres pour plonger tout en douceur et en légèreté dans l’intime du coeur et renouer avec son corps.
                                </p>
                                <p><strong>Feu sacré</strong></p>
                                <p>
                                    Un soin « recharge » idéal pour la saison froide qui vient réveiller votre feu intérieur et relancer l’énergie en profondeur.
                                </p>
                                <p><strong>Cristal lumière</strong></p>
                                <p>
                                    Du cristal, des sprays aromatiques et des huiles précieuses pour vous reconnecter avec votre lumière intérieure.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <Pricing />

                <Footer />

            </div>
        )
    }
}

export default WellBeingCarePage;