import React from "react";
import i18next from "i18next";
import {NavLink} from "react-router-dom";

class Footer extends React.Component {

    render = () => {

        return (
            <React.Fragment>
                <div className="pa-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="pa-foot-box">
                                    <h2 className="pa-foot-title">{i18next.t("menu.navigation")}</h2>
                                    <ul>
                                        <li>
                                            <NavLink to="/home">{i18next.t("menu.home")}</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/naturopathy">{i18next.t("menu.naturopathy")}</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/well-being-care">{i18next.t("menu.care")}</NavLink>
                                        </li>
                                        {false &&
                                            <li>
                                                <NavLink to="/blog">{i18next.t("menu.blog")}</NavLink>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="pa-foot-box">
                                    <h2 className="pa-foot-title">{i18next.t("footer.legal-information")}</h2>
                                    <ul>
                                        <li>
                                            <NavLink to="/about-us">{i18next.t("menu.about-us")}</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/privacy-policy">{i18next.t("menu.privacy-policy")}</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/terms-conditions">{i18next.t("menu.terms-conditions")}</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="pa-foot-box pa-foot-subscribe">
                                    <img src="/assets/images/logo-name.svg" alt="logo" className="img-fluid" style={{height: 80}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pa-copyright">
                    <div className="container">
                        <p>{i18next.t("general.copyright")}</p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Footer;