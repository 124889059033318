
import jwt_decode from "jwt-decode";

// Set the logged in user data in local session
const setLoggedInUser = (user) => {
    localStorage.setItem('astrid-frontend-user', JSON.stringify(user));
}

// Gets the logged in user data from local session
const getLoggedInUser = () => {
    const user = localStorage.getItem('astrid-frontend-user');
    if (user) {
        const json = JSON.parse(user);
        const decoded = jwt_decode(json.access_token);
        const now = new Date().getTime() / 1000;
        if (decoded.exp < now) {
            return null;
        }
        return json;
    }
    return null;
}

// If user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}

// Unset the logged in user data in local session
const unsetLoggedInUser = () => {
    localStorage.removeItem("astrid-frontend-user")
}

// Set the email in local session
const rememberMe = (email) => {
    localStorage.setItem('astrid-frontend-email', email);
}

// Unset the email in local session
const forgetMe = (email) => {
    localStorage.removeItem("astrid-frontend-email")
}

// Gets the email from local session
const getSavedEmail = () => {
    return localStorage.getItem('astrid-frontend-email');
}

export { setLoggedInUser, getLoggedInUser, isUserAuthenticated, unsetLoggedInUser, rememberMe, forgetMe, getSavedEmail }