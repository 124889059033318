import translation_en from "./en-US/translation.json";
import translation_es from "./es-ES/translation.json";
import translation_fr from "./fr-FR/translation.json";

export const locales = {
    en: {
        translation: translation_en
    },
    es: {
        translation: translation_es
    },
    fr: {
        translation: translation_fr
    }
}