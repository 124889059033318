import React from "react";
import i18next from "i18next";
import superagent from "superagent";
import {AppContext} from "../App";
import {NavLink} from "react-router-dom";

class BlogCategories extends React.Component {

    state = {
        categories: []
    }

    componentDidMount() {
        superagent
            .get(AppContext.hostName + '/v1/categories')
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .query({ pageSize: 4 })
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({categories: res.body})
                } else {
                    console.log(err);
                }
            });
    }

    render = () => {

        if (this.state.categories.length < 1) {
            return null;
        }

        return (
            <div className="pa-widget pa-shop-category">
                <h2 className="pa-sidebar-title">{i18next.t("blog.categories")}</h2>
                <ul>
                    {this.state.categories.map(function(category, index){
                        return (
                            <li key={index}><NavLink to={"/blog?category=" + category.id}>{category.name}</NavLink></li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

export default BlogCategories;