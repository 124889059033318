import React from "react";
import i18next from "i18next";
import moment from "moment";
import "moment/locale/fr";
import BlogPost from "./BlogPost";
import {AppContext} from "../App";
import superagent from "superagent";

class LatestNews extends React.Component {

    state = {
        posts: []
    }

    componentDidMount() {
        superagent
            .get(AppContext.hostName + '/v1/posts')
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .query({ pageSize: 3 })
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({posts: res.body})
                } else {
                    console.log(err);
                }
            });
    }

    render = () => {

        if (this.state.posts.length < 1) {
            return null
        }

        moment.locale(i18next.language);

        return (
            <div className="pa-blog spacer-top spacer-bottom">
                <div className="container">
                    <div className="pa-heading">
                        <img src="/assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                        <h1>{i18next.t("news.title")}</h1>
                        <h5>{i18next.t("news.subtitle")}</h5>
                    </div>
                    <div className="row">
                        {this.state.posts.map(function(post, index){
                            return (
                                <div key={index} className="col-lg-4 col-md-6">
                                    <BlogPost post={post} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default LatestNews;
