import React from "react";
import Header from "../components/Header";
import BlogSearch from "../components/BlogSearch";
import BlogCategories from "../components/BlogCategories";
import BlogTags from "../components/BlogTags";
import Footer from "../components/Footer";
import BlogRecentPosts from "../components/BlogRecentPosts";
import i18next from "i18next";
import {NavLink} from "react-router-dom";
import moment from "moment";
import superagent from "superagent";
import {AppContext} from "../App";
import {FacebookShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";

class PostPage extends React.Component {

    state = {
        post: null
    }

    componentDidMount() {
        this.getPost(this.props.match.params.id)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.post != null) {
            if (prevState.post.id !== this.props.match.params.id) {
                this.getPost(this.props.match.params.id)
            }
        }
    }

    getPost = (id) => {
        superagent
            .get(AppContext.hostName + '/v1/posts/' + id)
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({post: res.body})
                } else {
                    console.log(err);
                }
            });
    }

    render = () => {
        if (this.state.post == null) {
            return null
        }

        return (
            <div className="pa-main-wrapper">
                <Header />

                <div className="pa-breadcrumb">
                    <div className="container-fluid">
                        <div className="pa-breadcrumb-box">
                            <h1>{this.state.post.title}</h1>
                            <ul>
                                <li><NavLink to="/home">{i18next.t("menu.home")}</NavLink></li>
                                <li><NavLink to="/blog">{i18next.t("menu.blog")}</NavLink></li>
                                <li>{this.state.post.title}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="pa-blog-single spacer-top spacer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="pa-blog-box">
                                    <img src={this.state.post.image} alt={this.state.post.title} className="img-fluid"/>
                                        <div className="pa-blog-title">
                                            <NavLink to={"/blog?category=" + this.state.post.category.id} className="pa-blog-category">{this.state.post.category.name}</NavLink>

                                            <div>
                                            {this.state.post.tags.map(function(tag, index){
                                                return (
                                                    <span key={index}>
                                                        <NavLink to={"/blog?tag=" + tag.id} className="pa-blog-tag">{tag.name}</NavLink>
                                                        &nbsp;
                                                    </span>
                                                )
                                            })}
                                            </div>

                                            <h2>{this.state.post.title}</h2>
                                            <p>{moment(this.state.post.date).format(i18next.t("general.format-date"))}</p>
                                            <p dangerouslySetInnerHTML={{__html: this.state.post.summary}} />
                                        </div>
                                        <div className="pa-blog-view">
                                            <ul>
                                                <li>
                                                    <i className="fas fa-share-alt"></i> {i18next.t("blog.share")}
                                                    <FacebookShareButton quote={this.state.post.title} url={'https://astrid-bigot.fr/post/' + this.state.post.id} >
                                                        <i className="fab fa-facebook" style={{fontSize: 24, color: "#B7884D"}}/>
                                                    </FacebookShareButton>
                                                    <span> </span>
                                                    <TwitterShareButton title={this.state.post.title} url={'https://astrid-bigot.fr/post/' + this.state.post.id}>
                                                        <i className="fab fa-twitter" style={{fontSize: 24, color: "#B7884D"}}/>
                                                    </TwitterShareButton>
                                                    <span> </span>
                                                    <WhatsappShareButton url={'https://astrid-bigot.fr/post/' + this.state.post.id}>
                                                        <i className="fab fa-whatsapp" style={{fontSize: 24, color: "#B7884D"}}/>
                                                    </WhatsappShareButton>
                                                </li>
                                            </ul>
                                        </div>
                                </div>
                                <div className="pa-bs-content">
                                    <div dangerouslySetInnerHTML={{__html: this.state.post.text}} />
                                    <div className="pa-blog-user">
                                        <div className="pa-blog-user-img">
                                            <img src="https://res.cloudinary.com/dbbtkfgnk/image/upload/v1635360783/production/general/astrid2.jpg" alt="Astrid Bigot"
                                                 className="img-fluid"/>
                                        </div>
                                        <div className="pa-blog-user-content">
                                            <h2 className="pa-blog-user-name">Astrid Bigot</h2>
                                            <p>Naturopathe - Aromatologue</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="pa-blog-sidebar">
                                    <BlogSearch parent={this}/>

                                    <BlogCategories />

                                    <BlogRecentPosts />

                                    <BlogTags />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </div>
    )
    }
}

export default PostPage;