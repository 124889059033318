import React from "react";
import i18next from "i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import {AppContext} from "../App";
import superagent from "superagent";

import 'swiper/swiper-bundle.css';

class Testimonial extends React.Component {

    state = {
        testimonials: []
    }

    constructor(props) {
        super(props);
        SwiperCore.use([Navigation]);
    }

    componentDidMount() {
        superagent
            .get(AppContext.hostName + '/v1/testimonials')
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({testimonials: res.body})
                } else {
                    console.log(err);
                }
            });
    }

    render = () => {
        if (this.state.testimonials.length < 1) {
            return null
        }

        return (
            <div className="pa-testimonial spacer-top spacer-bottom">
                <div className="container">
                    <div className="pa-heading">
                        <img src="/assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                        <h1>{i18next.t("testimonial.title")}</h1>
                        <h5>{i18next.t("testimonial.subtitle")}</h5>
                    </div>

                    <Swiper slidesPerView={1}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                    >
                        {this.state.testimonials.map(function(testimonial, index){
                            return (
                                <SwiperSlide key={index}>
                                    <div className="pa-testimonial-box">
                                        <div className="pas-testi-img">
                                            <img src={testimonial.image} alt={testimonial.name}
                                                 className="img-fluid"/>
                                        </div>
                                        <div className="pas-testi-content">
                                            <h2>{testimonial.name}</h2>
                                            <p>{testimonial.message}</p>
                                            <div className="pa-testimonial-button">
                                                <div className="swiper-button-next"/>
                                                <div className="swiper-button-prev"/>
                                            </div>
                                        </div>

                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>

                </div>
            </div>
        )
    }
}

export default Testimonial;