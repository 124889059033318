import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import i18next from "i18next";
import {NavLink} from "react-router-dom";
import BlogCategories from "../components/BlogCategories";
import BlogTags from "../components/BlogTags";
import BlogSearch from "../components/BlogSearch";
import BlogPost from "../components/BlogPost";
import superagent from "superagent";
import {AppContext} from "../App";
import Pager from "../components/Pager";

class BlogPage extends React.Component {

    state = {
        posts: [],
        pageCount: 0,
        totalCount: 0,
        currentPage: 1
    }

    componentDidMount() {
        const url = new URL(window.location.href);
        const category = url.searchParams.get("category");
        const tag = url.searchParams.get("tag");
        const search = url.searchParams.get("search");
        this.getPosts(category, tag, search);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search !== this.props.location.search) {
            const url = new URL(window.location.href);
            const category = url.searchParams.get("category");
            const tag = url.searchParams.get("tag");
            const search = url.searchParams.get("search");
            this.getPosts(category, tag, search);
        }
    }

    getPosts = (category, tag, search) => {
        let currentPage = 1;
        const url = new URL(window.location.href);
        const cp = url.searchParams.get("currentPage");
        if (cp != null) {
            currentPage = cp
        }

        superagent
            .get(AppContext.hostName + '/v1/posts')
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .query({ pageSize: 4, currentPage: currentPage, category: category, tag: tag, search: search })
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({
                        pageCount: parseInt(res.headers['x-pagination-page-count'], 10),
                        totalCount: parseInt(res.headers['x-pagination-total-count'], 10),
                        currentPage: parseInt(res.headers['x-pagination-current-page'], 10),
                        posts: res.body
                    })
                } else {
                    console.log(err);
                }
            });
    }

    render = () => {
        /*
        if (this.state.posts.length < 1) {
            return null
        }
         */

        return (
            <div className="pa-main-wrapper">
                <Header />

                <div className="pa-breadcrumb">
                    <div className="container-fluid">
                        <div className="pa-breadcrumb-box">
                            <h1>{i18next.t("menu.blog")}</h1>
                            <ul>
                                <li><NavLink to="/home">{i18next.t("menu.home")}</NavLink></li>
                                <li>{i18next.t("menu.blog")}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="pa-blog-page spacer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="row">
                                    {this.state.posts.map(function(post, index){
                                        return (
                                            <div key={index} className="col-md-6">
                                                <BlogPost post={post} />
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="row">
                                    <Pager parent={this} href={window.location.href} pageCount={this.state.pageCount} currentPage={this.state.currentPage} />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="pa-blog-sidebar">
                                    <BlogSearch parent={this} />

                                    <BlogCategories />

                                    <BlogTags />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </div>
        )
    }
}

export default BlogPage;