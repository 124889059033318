import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import i18next from "i18next";
import {NavLink} from "react-router-dom";
import Pricing from "../components/Pricing";

class NaturopathyPage extends React.Component {

    render = () => {

        return (
            <div className="pa-main-wrapper">
                <Header />

                <div className="pa-breadcrumb">
                    <div className="container-fluid">
                        <div className="pa-breadcrumb-box">
                            <h1>{i18next.t("menu.naturopathy")}</h1>
                            <ul>
                                <li><NavLink to="/home">{i18next.t("menu.home")}</NavLink></li>
                                <li>{i18next.t("menu.naturopathy")}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="pa-services spacer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="pa-services-img">
                                    <img src="https://res.cloudinary.com/dbbtkfgnk/image/upload/v1630831751/production/general/herbs-and-roots-in-herbal-medicine.jpg" alt="herbs and roots" className="img-fluid"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="pa-services-content">
                                    <div className="pa-heading">
                                        <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                                        <h1>{i18next.t("naturopathy.title")}</h1>
                                        <h5>{i18next.t("naturopathy.subtitle")}</h5>
                                    </div>
                                    <p>Reconnue comme une médecine traditionnelle par l’Organisation Mondiale de la Santé (OMS) , la naturopathie est « un ensemble de méthodes de soins visant à renforcer les défenses de l’organisme par des moyens considérés comme naturels et biologiques ».
                                        C’est l’art de conserver, optimiser ou recouvrer la santé par des moyens naturels.
                                        La naturopathie holistique s’intéresse à tous les plans de l’existence (physique, énergétique, émotionnel..).
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row spacer-top">
                            <div className="col-lg-12">
                                <p>La naturopathie holistique s’intéresse à tous les plans de l’existence (physique, énergétique, émotionnel, mental, spirituel, socioculturel et planétaire). Ainsi, la personne est considérée dans son intégralité car tous les plans interagissent ensemble. Cette vision d’ensemble est au service d’une meilleure compréhension de la personne et de son terrain.
                                    Le praticien de santé naturopathe est avant tout un éducateur de santé, il ne s’oppose donc pas à la médecine allopathique ou aux médecines douces mais est dans une optique de complémentarité. Il ne pose pas de diagnostic, ne prescrit pas de médicaments et ne s’ingère pas dans un traitement médical en cours.
                                </p>
                                <p>Les séances de naturopathie de donnent pas lieu à un remboursement par la Sécurité sociale mais peuvent être prises en compte par certaines mutuelles.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pa-naturopathy spacer-top spacer-bottom">
                    <div className="container">
                        <div className="pa-heading">
                            <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                            <h1>{i18next.t("naturopathy.why-title")}</h1>
                            <h5>{i18next.t("naturopathy.why-subtitle")}</h5>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-6 pr-0">
                                <div className="pa-naturopathy-ul pa-naturopathy-left">
                                    <ul>
                                        <li>{i18next.t("naturopathy.why-arg1")}</li>
                                        <li>{i18next.t("naturopathy.why-arg2")}</li>
                                        <li>{i18next.t("naturopathy.why-arg3")}</li>
                                        <li>{i18next.t("naturopathy.why-arg4")}</li>
                                        <li>{i18next.t("naturopathy.why-arg5")}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 p-0">
                                <div className="pa-naturopathy-img">
                                    <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 pl-0">
                                <div className="pa-naturopathy-ul pa-naturopathy-right">
                                    <ul>
                                        <li>{i18next.t("naturopathy.why-arg6")}</li>
                                        <li>{i18next.t("naturopathy.why-arg7")}</li>
                                        <li>{i18next.t("naturopathy.why-arg8")}</li>
                                        <li>{i18next.t("naturopathy.why-arg9")}</li>
                                        <li>{i18next.t("naturopathy.why-arg10")}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pa-product spacer-top spacer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="pa-why-pure-content">
                                    <div className="pa-heading">
                                        <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                                        <h1>{i18next.t("pricing.first-session")}</h1>
                                        <h5>{i18next.t("pricing.first-session-subtitle")}</h5>
                                    </div>
                                    <p>La 1ère séance, un peu plus longue que les consultations de suivi (1h30) se déroule de la façon suivante :</p>
                                    <ul className="pa-list">
                                        <li>Questionnement (anamnèse) en passant en revue les différents systèmes de votre corps afin d’appréhender votre être dans sa globalité.</li>
                                        <li>Réalisation d'un bilan de vitalité qui va me permettre d’ajuster le travail sur le terrain et d’accompagner au mieux vos processus d’auto-régénération</li>
                                    </ul>
                                    <p>A l’issue de ce travail, remise d'un programme d’hygiène vitale individualisé.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pa-product spacer-top spacer-bottom" style={{backgroundColor: "#D8BE9F"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="pa-why-pure-content">
                                    <div className="pa-heading">
                                        <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                                        <h1>{i18next.t("pricing.follow-up-session")}</h1>
                                        <h5>{i18next.t("pricing.follow-up-session-subtitle")}</h5>
                                    </div>
                                    <p>Lors des séances de suivi, nous faisons le point sur ce que vous avez réussi à mettre en pratique ou non afin d’ajuster, de faire évoluer le programme d’hygiène vitale pour qu’il soit pleinement au service de votre santé.</p>
                                    <p>Il est très important pour moi de vous donner des clefs afin que vous puissiez être autonome dans votre hygiène de vie quotidienne.</p>
                                    <p>Des exercices pratiques sont intégrés dans ces séances.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pa-product spacer-top spacer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="pa-why-pure-content">
                                    <div className="pa-heading">
                                        <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                                        <h1>{i18next.t("pricing.care-session")}</h1>
                                        <h5>{i18next.t("pricing.care-session-subtitle")}</h5>
                                    </div>
                                    <p>Parce qu’il est primordial de revenir au corps, de lui redonner sa place dans un environnement où bien souvent le mental prédomine, compléter votre séance de suivi avec ces parenthèses enchantées.</p>
                                    <ul className="pa-list">
                                        <li><strong>Onctions aromatiques</strong> : selon votre état du moment, je réalise un mélange à base d’huiles essentielles appropriées  que je viens appliquer sur certaines zones du corps. C’est un moment de détente, de ressourcement.</li>
                                        <li><strong>Relaxation / visualisations</strong> : guidé par le son de ma voix et de la musique, laissez votre esprit s’apaiser et votre corps se reposer et se détendre.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Pricing />

                <Footer />

            </div>
        )
    }
}

export default NaturopathyPage;