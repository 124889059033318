import React from "react";
import { Redirect } from "react-router-dom";
import NotFoundPage from "../pages/NotFoundPage";
import HomePage from "../pages/HomePage";
import AboutUsPage from "../pages/AboutUsPage";
import BlogPage from "../pages/BlogPage";
import PostPage from "../pages/PostPage";
import NaturopathyPage from "../pages/NaturopathyPage";
import WellBeingCarePage from "../pages/WellBeingCarePage";
import AppointmentPage from "../pages/AppointmentPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import TermsConditionsPage from "../pages/TermsConditionsPage";
import InternshipPage from "../pages/InternshipPage";

const authProtectedRoutes = [
];

const publicRoutes = [
    { path: "/about-us", component: AboutUsPage },
    { path: "/appointment", component: AppointmentPage },
    { path: "/blog", component: BlogPage },
    { path: "/post/:id", component: PostPage },
    { path: "/home", component: HomePage },
    { path: "/naturopathy", component: NaturopathyPage },
    { path: "/privacy-policy", component: PrivacyPolicyPage },
    { path: "/terms-conditions", component: TermsConditionsPage },
    { path: "/internship", component: InternshipPage },
    { path: "/well-being-care", component: WellBeingCarePage },
    { path: "/", exact: true, component: () => <Redirect to="/home" /> },
    { path: "*", component: NotFoundPage },
];

export { authProtectedRoutes, publicRoutes };
