import React from "react";
import $ from "jquery";

class Preloader extends React.Component {

    constructor(props) {
        super(props);
        $(window).on("load", function() {
            $(".pa-ellipsis").fadeOut();
            $(".pa-preloader").delay(200).fadeOut("slow")
        });
    }

    render = () => {
        return (
            <div className="pa-preloader">
                <div className="pa-ellipsis">
                    <span/>
                    <span/>
                    <span/>
                </div>
            </div>
        )
    }
}

export default Preloader;