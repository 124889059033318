import React from "react";
import Header from "../components/Header";
import i18next from "i18next";
import {NavLink} from "react-router-dom";
import Footer from "../components/Footer";
import Contacts from "../components/Contacts";

class InternshipPage extends React.Component {

    render = () => {

        return (
            <div className="pa-main-wrapper">
                <Header />

                <div className="pa-breadcrumb">
                    <div className="container-fluid">
                        <div className="pa-breadcrumb-box">
                            <h1>{i18next.t("menu.internship")}</h1>
                            <ul>
                                <li><NavLink to="/home">{i18next.t("menu.home")}</NavLink></li>
                                <li>{i18next.t("menu.internship")}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="pa-services spacer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="pa-services-img">
                                    <img src="https://res.cloudinary.com/dbbtkfgnk/image/upload/v1673199536/production/general/stage.jpg" alt="bottle of essential oil" className="img-fluid"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="pa-services-content">
                                    <div className="pa-heading">
                                        <img src="/assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                                        <h1>{i18next.t("internship.title")}</h1>
                                        <h5 className={"text-uppercase"}>{i18next.t("internship.subtitle")}</h5>
                                    </div>
                                    <p>
                                        « Quand le sexe se transforme en amour. Pour passer de l’un à l’autre, nous avons besoin d’un seul ingrédient : la présence. Celle-ci nous offre un espace vierge où nos cœurs et nos sexes peuvent s’épouser librement à chaque instant. »
                                    </p>
                                    <p className="text-right">
                                        Jean-Philippe Ruette, Au-delà des écrans
                                    </p>
                                    <p>
                                        <strong>Dates : </strong>
                                        du jeudi 8 juin 2023, 9H30 au dimanche 11 juin 2023, 17H.
                                    </p>
                                    <p>
                                        <strong>Lieu : </strong>
                                        Mourèze (Hérault)
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row spacer-top">
                            <div className="col-lg-12">
                                <p>
                                    Trop souvent, notre sexualité est vécue au travers de nos conditionnements, de nos croyances, de notre éducation et de tout ce qui est véhiculé par la société (pornographie…). Faire l’amour devient une quête de performance où il faudrait atteindre un but, une nouvelle injonction, quelque chose « à réussir ». Le cœur n’a plus vraiment sa place dans ces ébats mécaniques, pâles et sans saveur.
                                </p>
                                <p>
                                    Et si nous changions de regard pour nous ouvrir à une sexualité différente, reconnectée au cœur ? Et si nous redonnions à notre sexualité cette empreinte de sacré qui est sa véritable essence ?
                                </p>
                                <p>
                                    Alors nous pourrons naître à nous-même avec une conscience renouvelée, prendre contact en douceur avec le sacré qui réside en chacun-e de nous, et nous émerveiller face au mystère du vivant, honorer chaque être.
                                </p>
                                <p>
                                    C’est ce à quoi nous vous convions. Ensemble, osons explorer d’autres possibles, bien plus vastes et plus subtils que le connu.
                                </p>
                                <p>
                                    Ce chemin de transformation est ouvert aux femmes et aux hommes animés par le désir de se découvrir et de vivre leur sexualité de manière plus ancrée et plus consciente. Quelle que soit votre orientation sexuelle, que vous soyez en couple ou non, cette proposition est ouverte à tous à partir de 18 ans.
                                </p>
                                <p><strong>LE CADRE</strong></p>
                                <p>
                                    Il se veut sécure pour tous, et soutenant, dans le respect du rythme, des limites et des capacités de chacun-e.
                                    Nous vous offrons une écoute active et bienveillante, une vraie présence.
                                    La question du consentement est fondamentale et restera au coeur des pratiques tout au long du stage.
                                    Il n'y aura aucun passage à l'acte sexuel lors des structures.
                                </p>
                                <p>
                                    Voici les différentes phases qui seront explorées, axées - entre autres - sur la méditation, le souffle, les sons et fragrances sacrés, les mouvements, les rituels...
                                </p>
                                <ul className="pa-list">
                                    <li>S’incarner, s’ancrer : puiser l’énergie de la terre, nourrir ses racines</li>
                                    <li>Habiter son périnée : remettre de la conscience dans le bassin pour éveiller l’énergie de vie</li>
                                    <li>Se dévoiler et rayonner : oser nos émotions et danser la vie</li>
                                    <li>À l’intime du cœur : descendre dans l’espace du cœur et se rencontrer vraiment</li>
                                    <li>Sex’primer : ouvrir la voix pour libérer la voie</li>
                                    <li>L’union sacrée : rencontrer notre couple intérieur</li>
                                </ul>
                                <p>
                                    <strong>Astrid BIGOT</strong> pratique la naturopathie et l’olfactothérapie. Elle chemine avec le tantra et d’autres enseignements spirituels.
                                </p>
                                <p>
                                    <strong>Clara ANDRÉ</strong> pratique la sophrologie des profondeurs, le tantra, la mantrathérapie traditionnelle tibétaine et les massages (tibétains, taoïstes et tantriques).
                                </p>
                                <p>
                                    Nous sommes des exploratrices passionnées de la conscience et de l'âme humaine !
                                </p>
                                <p><strong>LE TARIF</strong></p>
                                <p>
                                    Pour l’ensemble du stage est fixé à 595 € par personne, en pension complète.<br/>
                                    Toute inscription sera validée après versement d'un acompte, soit 120 € (non remboursables en cas d’annulation de votre part moins d'un mois avant le début du stage, sauf cas de force majeure).
                                </p>
                                <p>
                                    Une fois l'acompte versé, vous recevrez des informations plus détaillées sur l'organisation du séjour et du stage.
                                </p>
                                <p><strong>L'HÉBERGEMENT</strong></p>
                                <p>
                                    La plupart des chambres sont doubles ou triples.<br/>
                                    Si vous souhaitez dormir dans une chambre individuelle, prévoir 44 € par nuit en supplément.<br/>
                                    Pour toute nuit supplémentaire (veille et lendemain du stage) : 46 € par nuit en chambre double ou triple ; 90 € en chambre individuelle.
                                </p>
                                <p>En option :</p>
                                <ul className="pa-list">
                                    <li>parure de draps et taie d'oreiller 8 € pour l'ensemble du séjour.</li>
                                    <li>linge de toilette 4 € pour l'ensemble du séjour.</li>
                                </ul>
                                <p><strong>LES REPAS</strong></p>
                                <p>
                                    Les repas sont végétariens. En cas de régime particulier (allergies et intolérances), merci de nous prévenir pour que les personnes chargées de la cuisine puissent adapter les menus.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <Contacts />

                <Footer />

            </div>
        )
    }
}

export default InternshipPage;