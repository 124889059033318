import React from "react";
import Header from "../components/Header";
import i18next from "i18next";
import {NavLink} from "react-router-dom";
import Footer from "../components/Footer";

class PrivacyPolicyPage extends React.Component {

    renderEnglish = () => {
        // https://www.termsfeed.com/legal/privacy-policy/
        return (
            <div className="pa-about spacer-top spacer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="pa-about-content">
                                <div className="pa-heading">
                                    <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid"/>
                                    <h1>{i18next.t("menu.privacy-policy")}</h1>
                                    <h5>5 September 2021</h5>
                                </div>
                                <p>Our Privacy Policy governs the privacy terms of our website, located at
                                    https://astrid-bigot.fr, and the tools we provide you (the "Website" or the "Service"). Any
                                    capitalized terms not defined in our Privacy Policy, have the meaning as specified
                                    in our Terms and Conditions accessible at <NavLink
                                        to="/terms-conditions">https://astrid-bigot.fr/terms-conditions</NavLink>.
                                </p>

                                <h4 className="mt-4">Your Privacy</h4>
                                <p>Our Website follows all legal requirements to protect your privacy. Our Privacy Policy is a legal statement that explains how we may collect information from you, how we may share your information, and how you can limit our sharing of your information. You will see terms in our Privacy Policy that are capitalized. These terms have meanings as described in the Definitions section below.</p>

                                <h4 className="mt-4">Definitions</h4>
                                <ul>
                                    <li><strong>Personal Data</strong>: Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</li>
                                    <li><strong>Usage Data</strong>: Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
                                    <li><strong>Cookies</strong>: Cookies are small pieces of data stored on a User's device.</li>
                                    <li><strong>Data Controller</strong>: Data Controller means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</li>
                                    <li><strong>Data Processors (or Service Providers)</strong>: Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</li>
                                    <li><strong>Data Subject</strong> :Data Subject is any living individual who is the subject of Personal Data.</li>
                                    <li><strong>User</strong>: The User is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</li>
                                </ul>

                                <h4 className="mt-4">Types of Data Collected</h4>
                                <h6>Personal Data</h6>
                                <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to: Email address, Name, Address, State, Province, ZIP/Postal code, City, Cookies and Usage Data.</p>
                                <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</p>

                                <h6>Usage Data</h6>
                                <p>We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

                                <h6>Tracking & Cookies Data</h6>
                                <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
                                <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
                                <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                                <p>Examples of Cookies we use: session cookies (we use these cookies to operate our Service) and preference cookies (we use these cookies to remember your preferences and various settings).</p>

                                <h4 className="mt-4">Use of Data</h4>
                                <p>We use the collected data for various purposes:</p>
                                <ul>
                                    <li>To provide and maintain our Service</li>
                                </ul>

                                <h4 className="mt-4">Retention of Data</h4>
                                <p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                                <p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>

                                <h4 className="mt-4">Transfer Of Data</h4>
                                <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>

                                <h4 className="mt-4">Disclosure Of Data</h4>
                                <h6>Business Transaction.</h6>
                                <p>If we are involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>

                                <h6>Disclosure for Law Enforcement.</h6>
                                <p>Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>

                                <h4 className="mt-4">Security Of Data</h4>
                                <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                                <h4 className="mt-4">Legal Basis for Processing Personal Data Under General Data Protection Regulation (GDPR)</h4>
                                <p>If you are from the European Economic Area (EEA), TermsFeed legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</p>
                                <p>TermsFeed may process your Personal Data because:</p>
                                <ul>
                                    <li>We need to perform a contract with you</li>
                                    <li>You have given us permission to do so</li>
                                    <li>The processing is in our legitimate interests and it's not overridden by your rights</li>
                                    <li>For payment processing purposes</li>
                                    <li>To comply with the law</li>
                                </ul>

                                <h4 className="mt-4">Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h4>
                                <p>f you are a resident of the European Economic Area (EEA), you have certain data protection rights. TermsFeed aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
                                <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
                                <p>In certain circumstances, you have the following data protection rights:</p>
                                <ul>
                                    <li><strong>The right to access, update or to delete</strong> the information we have on you.</li>
                                    <li><strong>The right of rectification</strong>. You have the right to have your information rectified if that information is inaccurate or incomplete.</li>
                                    <li><strong>The right to object</strong>. You have the right to object to our processing of your Personal Data.</li>
                                    <li><strong>The right of restriction</strong>. You have the right to request that we restrict the processing of your personal information.</li>
                                    <li><strong>The right to data portability</strong>. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format.</li>
                                    <li><strong>The right to withdraw consent</strong>. You also have the right to withdraw your consent at any time where TermsFeed relied on your consent to process your personal information.</li>
                                </ul>
                                <p>Please note that we may ask you to verify your identity before responding to such requests.</p>
                                <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>

                                <h4 className="mt-4">Links To Other Sites</h4>
                                <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

                                <h4 className="mt-4">Children's Privacy</h4>
                                <p>Our Service does not address anyone under the age of 18 ("Children").</p>
                                <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>

                                <h4 className="mt-4">Changes To This Privacy Policy</h4>
                                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                                <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
                                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

                                <h4 className="mt-4">Contact Us</h4>
                                <p>If you have any questions about this Privacy Policy, please contact us by using the contact information we provided on our Contact page.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderFrench0 = () => {
        // https://www.termsfeed.com/legal/privacy-policy/
        return (
            <div className="pa-about spacer-top spacer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="pa-about-content">
                                <div className="pa-heading">
                                    <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid"/>
                                    <h1>{i18next.t("menu.privacy-policy")}</h1>
                                    <h5>5 septembre 2021</h5>
                                </div>

                                <p>A compléter</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderFrench = () => {
        // https://www.termsfeed.com/legal/privacy-policy/
        return (
            <div className="pa-about spacer-top spacer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="pa-about-content">
                                <div className="pa-heading">
                                    <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid"/>
                                    <h1>{i18next.t("menu.privacy-policy")}</h1>
                                    <h5>5 septembre 2021</h5>
                                </div>
                                <p>Notre politique de confidentialité régit les conditions de confidentialité de notre site Web, situé à
                                    https://astrid-bigot.fr, et les outils que nous mettons à votre disposition (le "Site" ou le "Service"). Tout
                                    les termes en majuscules non définis dans notre politique de confidentialité, ont la signification spécifiée
                                    dans nos conditions générales accessibles sur <NavLink
                                        to="/terms-conditions">https://astrid-bigot.fr/terms-conditions</NavLink>.
                                </p>

                                <h4 className="mt-4">Votre vie privée</h4>
                                <p>Notre site Web respecte toutes les exigences légales pour protéger votre vie privée. Notre politique de confidentialité est une déclaration légale qui explique comment nous pouvons collecter des informations auprès de vous, comment nous pouvons partager vos informations et comment vous pouvez limiter notre partage de vos informations. Vous verrez des termes dans notre politique de confidentialité qui sont en majuscules. Ces termes ont la signification décrite dans la section Définitions ci-dessous.</p>

                                <h4 className="mt-4">Définitions</h4>
                                <ul className="pa-list">
                                    <li><strong>Données personnelles</strong> : Les données personnelles désignent les données sur une personne vivante qui peuvent être identifiées à partir de ces données (ou à partir de celles-ci et d'autres informations en notre possession ou susceptibles d'entrer en notre possession).</li>
                                    <li><strong>Données d'utilisation</strong> : Les données d'utilisation sont des données collectées automatiquement, soit générées par l'utilisation du service, soit à partir de l'infrastructure du service elle-même (par exemple, la durée d'une visite de page).</li>
                                    <li><strong>Cookies</strong> : Les cookies sont de petits morceaux de données stockés sur l'appareil d'un utilisateur.</li>
                                    <li><strong>Responsable du traitement</strong> : Responsable du traitement désigne une personne physique ou morale qui (seule ou conjointement ou en commun avec d'autres personnes) détermine les finalités pour lesquelles et la manière dont les données personnelles sont ou doivent être traitées. Aux fins de cette politique de confidentialité, nous sommes un responsable du traitement de vos données.</li>
                                    <li><strong>Sous-traitant (ou Fournisseurs de services)</strong> : Sous-traitant (ou fournisseur de services) désigne toute personne physique ou morale qui traite les données pour le compte du responsable du traitement. Nous pouvons utiliser les services de divers fournisseurs de services afin de traiter vos données plus efficacement.</li>
                                    <li><strong>Personne concernée</strong> : La Personne concernée est tout individu vivant qui fait l'objet de Données personnelles.</li>
                                    <li><strong>Utilisateur</strong> : L'Utilisateur est la personne physique utilisant notre Service. L'Utilisateur correspond à la Personne Concernée, qui fait l'objet de Données Personnelles.</li>
                                </ul>

                                <h4 className="mt-4">Types de données collectées</h4>
                                <h6>Données personnelles</h6>
                                <p>Lors de l'utilisation de notre Service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui peuvent être utilisées pour vous contacter (« Données personnelles »). Les informations personnellement identifiables sont : l'adresse e-mail et le nom.</p>
                                <p>Nous pouvons utiliser vos Données personnelles pour vous contacter et organiser un rendez-vous.</p>

                                <h6>Données d'utilisation</h6>
                                <p>Nous ne collectons aucune donnée d'utilisation de notre site Web.</p>

                                <h6>Données de suivi et cookies</h6>
                                <p>Nous n'utilisons aucun cookie.</p>

                                <h4 className="mt-4">Utilisation des données</h4>
                                <p>Nous utilisons les données collectées uniquement pour organiser un rendez-vous.</p>

                                <h4 className="mt-4">Conservations des données</h4>
                                <p>Vos informations sont conservées exclusivement en France.</p>
                                <p>Nous ne conserverons vos données personnelles que le temps nécessaire aux fins énoncées dans la présente politique de confidentialité. Nous conserverons et utiliserons vos données personnelles dans la mesure nécessaire pour nous conformer à nos obligations légales (par exemple, si nous sommes tenus de conserver vos données pour nous conformer aux lois applicables), résoudre les litiges et appliquer nos accords et politiques juridiques.</p>

                                <h4 className="mt-4">Transfert de données</h4>
                                <p>Vos informations, y compris les données personnelles, ne sont jamais transférés à d'autres personnes, sociétés ou organismes.</p>

                                <h4 className="mt-4">Divulgation de données</h4>
                                <h6>Transaction d'affaires.</h6>
                                <p>Si nous sommes impliqués dans une fusion, une acquisition ou une vente d'actifs, vos données personnelles peuvent être transférées. Nous vous informerons avant que vos données personnelles ne soient transférées et soient soumises à une politique de confidentialité différente.</p>

                                <h6>Divulgation pour l'application de la loi.</h6>
                                <p>Dans certaines circonstances, nous pouvons être tenus de divulguer vos données personnelles si la loi l'exige ou en réponse à des demandes valables des autorités publiques (par exemple, un tribunal ou un organisme gouvernemental).</p>

                                <h4 className="mt-4">Sécurité des données</h4>
                                <p>La sécurité de vos données est importante pour nous, mais rappelez-vous qu'aucune méthode de transmission sur Internet ou méthode de stockage électronique n'est sécurisée à 100 %. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos données personnelles, nous ne pouvons garantir leur sécurité absolue.</p>

                                <h4 className="mt-4">Base juridique du traitement des données personnelles en vertu du règlement général sur la protection des données (RGPD)</h4>
                                <p>Si vous résidez dans l'Espace économique européen (EEE), la base juridique de astrid-bigot.fr pour la collecte et l'utilisation des informations personnelles décrites dans la présente politique de confidentialité dépend des données personnelles que nous collectons et du contexte spécifique dans lequel nous les collectons.</p>
                                <p>astrid-bigot.fr peut traiter vos données personnelles car :</p>
                                <ul className="pa-list">
                                    <li>Nous devons organiser un rendez-vous avec vous</li>
                                    <li>Vous nous avez donné la permission de le faire</li>
                                    <li>Le traitement est dans notre intérêt légitime et il n'est pas outrepassé par vos droits</li>
                                    <li>À des fins de traitement des paiements</li>
                                    <li>Pour se conformer à la loi</li>
                                </ul>

                                <h4 className="mt-4">Vos droits en matière de protection des données en vertu du règlement général sur la protection des données (RGPD)</h4>
                                <p>Si vous résidez dans l'Espace économique européen (EEE), vous disposez de certains droits en matière de protection des données. astrid-bigot.fr vise à prendre des mesures raisonnables pour vous permettre de corriger, modifier, supprimer ou limiter l'utilisation de vos données personnelles.</p>
                                <p>Si vous souhaitez être informé des données personnelles que nous détenons à votre sujet et si vous souhaitez qu'elles soient supprimées de nos systèmes, veuillez nous contacter.</p>
                                <p>Dans certaines circonstances, vous disposez des droits de protection des données suivants :</p>
                                <ul className="pa-list">
                                    <li><strong>Le droit d'accéder, de mettre à jour ou de supprimer</strong> les informations que nous avons sur vous.</li>
                                    <li><strong>Le droit de rectification</strong>. Vous avez le droit de faire rectifier vos informations si ces informations sont inexactes ou incomplètes.</li>
                                    <li><strong>Le droit d'opposition</strong>. Vous avez le droit de vous opposer à notre traitement de vos données personnelles.</li>
                                    <li><strong>Le droit de restriction</strong>. Vous avez le droit de demander que nous restreignions le traitement de vos informations personnelles.</li>
                                    <li><strong>Le droit à la portabilité des données</strong>. Vous avez le droit de recevoir une copie de vos données personnelles dans un format structuré, lisible par machine et couramment utilisé.</li>
                                    <li><strong>Le droit de retirer cotre consentement</strong>. Vous avez également le droit de retirer votre consentement à tout moment lorsque astrid-bigot.fr s'est appuyé sur votre consentement pour traiter vos informations personnelles.</li>
                                </ul>
                                <p>Veuillez noter que nous pouvons vous demander de vérifier votre identité avant de répondre à de telles demandes.</p>
                                <p>Vous avez le droit de vous plaindre auprès d'une autorité de protection des données de notre collecte et de notre utilisation de vos données personnelles. Pour plus d'informations, veuillez contacter votre autorité locale de protection des données dans l'Espace Economique Européen (EEE).</p>

                                <h4 className="mt-4">Liens vers d'autres sites</h4>
                                <p>Notre service peut contenir des liens vers d'autres sites qui ne sont pas exploités par nous. Si vous cliquez sur un lien tiers, vous serez dirigé vers le site de ce tiers. Nous vous conseillons vivement de consulter la politique de confidentialité de chaque site que vous visitez.</p>
                                <p>Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques de tout site ou service tiers.</p>

                                <h4 className="mt-4">Confidentialité des enfants</h4>
                                <p>Notre service ne s'adresse pas aux personnes de moins de 18 ans (« enfants »).</p>
                                <p>Nous ne collectons pas sciemment d'informations personnellement identifiables auprès de personnes de moins de 18 ans. Si vous êtes un parent ou un tuteur et que vous savez que vos enfants nous ont fourni des données personnelles, veuillez nous contacter. Si nous apprenons que nous avons collecté des données personnelles d'enfants sans vérification du consentement parental, nous prenons des mesures pour supprimer ces informations de nos serveurs.</p>

                                <h4 className="mt-4">Modifications de cette politique de confidentialité</h4>
                                <p>Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de tout changement en publiant la nouvelle politique de confidentialité sur cette page.</p>
                                <p>Nous vous informerons par e-mail et/ou par un avis bien visible sur notre Service, avant que le changement ne prenne effet et nous mettrons à jour la « date d'entrée en vigueur » en haut de la présente Politique de confidentialité.</p>
                                <p>Il vous est conseillé de consulter régulièrement cette politique de confidentialité pour tout changement. Les modifications apportées à cette politique de confidentialité entrent en vigueur lorsqu'elles sont publiées sur cette page.</p>

                                <h4 className="mt-4">Nous contacter</h4>
                                <p>Si vous avez des questions concernant cette politique de confidentialité veuillez nous contacter mail à contact@astrid-bigot.fr.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render = () => {
        return (
            <div className="pa-main-wrapper">

                <Header/>

                <div className="pa-breadcrumb">
                    <div className="container-fluid">
                        <div className="pa-breadcrumb-box">
                            <h1>{i18next.t("menu.privacy-policy")}</h1>
                            <ul>
                                <li><NavLink to="/home">{i18next.t("menu.home")}</NavLink></li>
                                <li>{i18next.t("menu.privacy-policy")}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {this.renderFrench()}

                <Footer/>

            </div>
        )
    }
}

export default PrivacyPolicyPage;