import React from "react";
import i18next from "i18next";
import superagent from "superagent";
import {AppContext} from "../App";
import {NavLink} from "react-router-dom";

class BlogTags extends React.Component {

    state = {
        tags: []
    }

    componentDidMount() {
        superagent
            .get(AppContext.hostName + '/v1/tags')
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({tags: res.body})
                } else {
                    console.log(err);
                }
            });
    }

    render = () => {

        if (this.state.tags.length < 1) {
            return null;
        }

        return (
            <div className="pa-widget pa-tag">
                <h2 className="pa-sidebar-title">{i18next.t("blog.tags")}</h2>
                <ul>
                    {this.state.tags.map(function(tag, index){
                        return (
                            <li key={index}><NavLink to={"/blog?tag=" + tag.id}>{tag.name}</NavLink></li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

export default BlogTags;