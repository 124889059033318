import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import i18next from "i18next";
import {NavLink} from "react-router-dom";
import superagent from "superagent";
import {AppContext} from "../App";

class AppointmentPage extends React.Component {

    state = {
        addresses: [],
        schedule: null
    }

    componentDidMount() {
        this.getAddresses();
        this.getSchedule();
    }

    getAddresses = () => {
        superagent
            .get(AppContext.hostName + '/v1/addresses')
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({
                        addresses: res.body
                    })
                } else {
                    console.log(err);
                }
            });
    }

    getSchedule = () => {
        superagent
            .get(AppContext.hostName + '/v1/schedule')
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({
                        schedule: res.body
                    })
                } else {
                    console.log(err);
                }
            });
    }

    renderDay = (address, day) => {
        return (
            <div>
                {day.time_slots.length > 0 &&
                    day.time_slots.map(function(slot, index){
                        if (slot.address === address) {
                            if (index > 0) {
                                return (
                                    <span key={index}> / {slot.start} - {slot.finish}</span>
                                )
                            } else {
                                return (
                                    <span key={index}>{i18next.t("appointment." + day.name)} : {slot.start} - {slot.finish}</span>
                                )
                            }
                        } else {
                            return null
                        }
                    })
                }

            </div>
        )
    }

    render = () => {
        if ((this.state.addresses.length < 1) || (this.state.schedule == null)) {
            return null
        }

        const that = this;

        return (
            <div className="pa-main-wrapper">
                <Header />

                <div className="pa-breadcrumb">
                    <div className="container-fluid">
                        <div className="pa-breadcrumb-box">
                            <h1>{i18next.t("menu.appointment")}</h1>
                            <ul>
                                <li><NavLink to="/home">{i18next.t("menu.home")}</NavLink></li>
                                <li>{i18next.t("menu.appointment")}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="pa-contact spacer-top">
                    <div className="container">

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="mb-5">
                                    <img src="/assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                                    <h1>Consultations</h1>
                                    <h4 className="">A mon cabinet ou en ligne</h4>
                                </div>
                                <p>
                                    Membre de l'OMNES, Organisation de la Médecine Naturelle et de l'Education Sanitaire, je vous reçois à mon cabinet ou en ligne.
                                </p>
                                <p>
                                    Vous pouvez-prendre rendez-vous par téléphone ou m'envoyer un message avec vos coordonnées et je vous rappelerai.
                                </p>
                                <p>
                                    Pour les consultations en ligne, je vous communiquerai les informations de connexion par mail après la prise de rendez-vous.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="pa-contact-box">
                                    <img src="/assets/images/logo-omnes.gif" height="60" alt="Omnes" />
                                    <span>
                                        <span>&nbsp;&nbsp;&nbsp;</span>
                                        <a href="https://www.omnes.fr" rel="noreferrer nopener" target="_blank"><span style={{fontSize: "20px", fontWeight: "500", fontFamily: "Playfair Display, serif"}}>Membre de l'OMNES</span></a>
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="pa-contact-box">
                                    <h4>{i18next.t("appointment.phone")}</h4>
                                    <p><a href="tel:+33-6.33.05.11.55">+33-6.33.05.11.55</a></p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="pa-contact-box">
                                    <h4>{i18next.t("appointment.email")}</h4>
                                    <p><a href="mailto:contact@astrid-bigot.fr">contact@astrid-bigot.fr</a></p>
                                </div>
                            </div>
                        </div>

                        {this.state.addresses.map(function(address, index){
                            return (
                                <div key={index} className="row spacer-bottom">
                                    <div className="col-md-7">
                                        <div className="pa-contact-map">
                                            <iframe
                                                title="Google Map"
                                                src={address.google_map}
                                                aria-hidden="false" tabIndex="0" />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="">
                                            <h1>{address.city}</h1>
                                        </div>

                                        <div className="pa-contact-box">
                                            <h4>{i18next.t("appointment.address")}</h4>
                                            <p>{address.floor}</p>
                                            <p>{address.building}</p>
                                            <p>{address.street}</p>
                                            <p>{address.locality}</p>
                                            <p>{address.postal_code} {address.city}</p>
                                        </div>

                                        <div className="pa-contact-box">
                                            <h4>{i18next.t("appointment.schedule")}</h4>
                                                {that.state.schedule.days.map(function(day, index){
                                                    return (
                                                        <div key={index}>{that.renderDay(address.id, day)}</div>
                                                    )
                                                })}
                                        </div>

                                        <div className="pa-contact-box">
                                            <h4>{i18next.t("appointment.access")}</h4>
                                            <p>{address.access}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}


                    </div>
                </div>

                <Footer />
            </div>
        )
    }
}

export default AppointmentPage;