import React from "react";
import i18next from "i18next";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";

class BlogSearch extends React.Component {

    state = {
        search: ""
    }

    componentDidMount() {
        const url = new URL(window.location.href);
        const search = url.searchParams.get("search");
        if (search) {
            this.setState({search: search})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const url = new URL(window.location.href);
        const search = url.searchParams.get("search");
        if (this.state.search !== search) {
            this.setState({search: search})
        }
    }

    handleSubmit = (values, { setErrors, setSubmitting }) => {
        const url = new URL(window.location.href);
        const currentPage = 1
        const category = url.searchParams.get("category");
        const tag = url.searchParams.get("tag");

        let pathname = "/blog?currentPage=" + currentPage;
        if (category) {
            pathname = pathname + "&category=" + category
        }
        if (tag) {
            pathname = pathname + "&tag=" + tag
        }
        if (values["search"].length > 0) {
            pathname = pathname + "&search=" + values["search"]
        }
        this.props.parent.props.history.push(pathname)
        return true
    }

    render = () => {
        return (
            <Formik
                enableReinitialize
                className="form-wrap"
                initialValues={{
                    search: this.state.search ? this.state.search : ""
                }}
                onSubmit={this.handleSubmit}
                validationSchema={Yup.object().shape({
                    search: Yup.string()
                })}
            >
                {props => {
                    return (
                        <Form className="form-horizontal mb-5" >
                            <div className="pa-widget pa-search">
                                <Field type="input" name="search" placeholder={i18next.t("blog.search-placeholder")}/>
                                <ErrorMessage name="search" className="error-message" component="div" />
                                <button type="submit" className="pa-btn">{i18next.t("blog.search")}</button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        )
    }
}

BlogSearch.propTypes = {
    parent: PropTypes.object.isRequired,
}

export default BlogSearch;