import React from "react";
import moment from "moment";
import i18next from "i18next";
import PropTypes from "prop-types";
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import {NavLink} from "react-router-dom";

class BlogPost extends React.Component {

    render = () => {
        return (
            <div className="pa-blog-box">
                <NavLink to={"/post/" + this.props.post.id}><img src={this.props.post.image} alt={this.props.post.title} className="img-fluid"/></NavLink>
                <div className="pa-blog-title">
                    <NavLink to={"/blog?category=" + this.props.post.category.id} className="pa-blog-category">{this.props.post.category.name}</NavLink>

                    <div>
                    {this.props.post.tags.map(function(tag, index){
                        return (
                            <span key={index}>
                                <NavLink to={"/blog?tag=" + tag.id} className="pa-blog-tag">{tag.name}</NavLink>
                                &nbsp;
                            </span>
                        )
                    })}
                    </div>

                    <h2>{this.props.post.title}</h2>
                    {moment(this.props.post.date).format(i18next.t("general.format-date"))}
                </div>
                <div className="pa-blog-view">
                    <ul>
                        <li>
                            <i className="fas fa-share-alt"/> {i18next.t("blog.share")}<span> </span>
                            <FacebookShareButton quote={this.props.post.title} url={'https://astrid-bigot.fr/post/' + this.props.post.id} >
                                <i className="fab fa-facebook" style={{fontSize: 24, color: "#B7884D"}}/>
                            </FacebookShareButton>
                            <span> </span>
                            <TwitterShareButton title={this.props.post.title} url={'https://astrid-bigot.fr/post/' + this.props.post.id}>
                                <i className="fab fa-twitter" style={{fontSize: 24, color: "#B7884D"}}/>
                            </TwitterShareButton>
                            <span> </span>
                            <WhatsappShareButton url={'https://astrid-bigot.fr/post/' + this.props.post.id}>
                                <i className="fab fa-whatsapp" style={{fontSize: 24, color: "#B7884D"}}/>
                            </WhatsappShareButton>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

BlogPost.propTypes = {
    post: PropTypes.object.isRequired
}

export default BlogPost;