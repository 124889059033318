import React from "react";
import i18next from "i18next";
import {NavLink} from "react-router-dom";

class Pricing extends React.Component {

    render = () => {

        return (
            <div className="pa-price-plan spacer-top" style={{backgroundColor: "#D8BE9F"}}>
                <div className="container">
                    <div className="pa-heading">
                        <img src="assets/images/herbal.svg" alt="herbal" className="img-fluid" style={{height: '70px'}}/>
                        <h1>{i18next.t("pricing.our-prices")}</h1>
                        <h5>{i18next.t("pricing.our-prices-subtitle")}</h5>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="pa-plan-box" style={{backgroundColor: "white"}}>
                                <h2 className="pa-plan-title">Ode à la pureté</h2>
                                <h2 className="pa-plan-price"> 150 <span>€</span></h2>
                                <ul>
                                    <li>Durée : 2h00</li>
                                </ul>
                                <NavLink to={"/appointment"} className="pa-btn">{i18next.t("pricing.appointment")}</NavLink>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="pa-plan-box" style={{backgroundColor: "white"}}>
                                <h2 className="pa-plan-title">Corps à coeur</h2>
                                <h2 className="pa-plan-price"> 150 <span>€</span></h2>
                                <ul>
                                    <li>Durée : 2h00</li>
                                </ul>
                                <NavLink to={"/appointment"} className="pa-btn">{i18next.t("pricing.appointment")}</NavLink>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="pa-plan-box" style={{backgroundColor: "white"}}>
                                <h2 className="pa-plan-title">Feu sacré</h2>
                                <h2 className="pa-plan-price"> 130 <span>€</span></h2>
                                <ul>
                                    <li>Durée : 1h30</li>
                                </ul>
                                <NavLink to={"/appointment"} className="pa-btn">{i18next.t("pricing.appointment")}</NavLink>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="pa-plan-box" style={{backgroundColor: "white"}}>
                                <h2 className="pa-plan-title">Cristal lumière</h2>
                                <h2 className="pa-plan-price"> 130 <span>€</span></h2>
                                <ul>
                                    <li>Durée : 1h30</li>
                                </ul>
                                <NavLink to={"/appointment"} className="pa-btn">{i18next.t("pricing.appointment")}</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Pricing;